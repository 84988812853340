body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 14px sans-serif;
  background-color: #ffffff;
overflow: auto;
  /*background: linear-gradient(-25deg, #dae2e6  30%, #EEEEEE 70%);*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus{
  outline: 0;
}

html, body, #root{
  height: 100%;

}

a{
  text-decoration: none;
  color: inherit;
}

a:focus, a:hover, a:active{
  color: #0b497c;
}

a:visited{
  color: inherit;
}


ul{
  list-style: none;
}

button{
  cursor: pointer;
}


.app-content{
  margin-left: 220px;
  padding: 10px;
  height: calc(100% - 65px);
  width: auto;
  overflow: auto;
 /* border: 2px solid rgb(255, 0, 0);*/
}

.cnt-sibe-fechado{
  margin-left:0;
  transition: all 0.5s 0.4s;
}


/* SCROLL BAR */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f7bba;
}


@media screen and (max-width: 995px){
  /*
  margin-left: 220px;
  padding: 1px 14px;
  */


  .app-content{
   /* margin-left: 50px; */
    margin-left:0;
    padding: 8px;
    height: calc(100% - 115px);
    overflow: auto;
  }
  
}