:root {
  --color-first          : #65587f;
  --color-second         : #f18867;
  --color-third          : #e85f99;
  --color-forth          : #50bda1;
  --block-width          :   300px;
  --block-height         :   270px;
  --border-width         : .625rem;
  --border-radius-outer  :     8px;
  --border-radius-inner  : calc(var(--border-radius-outer) / 2);
  --font-plain           : 'IBM Plex Sans', sans-serif;
  --font-special         : 'Fredoka One', sans-serif;
 
  box-sizing: border-box;
  line-height: 1.4
}

*, *:before, *:after { box-sizing: inherit }

.App{
  /* text-align: center;   */
}

.img-topo{
  background-color: rgb(3, 56, 120);
  width: 100%;
  height: 550px;
  background-position-x: 50%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  border-bottom-style: none;
  border-color: #000000;
  border-left-style: none;
  border-radius: 0px;
  border-right-style: none;
  border-top-style: none;
  border-width: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
}
.box-amarelo{
  background-color: rgb(244, 194, 22);
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: rgb(0, 60, 127);
    font-size: 34px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
}
/* -------------------------- */
.box-form{
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;

}
.box-form-a{

  width: 480px;
  height: 100%;
  color: rgb(3, 56, 120);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}
.box-form-b{
  width: 480px;
  height: 100%;
  padding: 10px;
 
}
/* -------------------------- */
.form{
  background-color: rgb(0, 60, 127);
  border-radius: 25px;
  padding: 30px;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;

}
.form-titulo{
 color: rgb(244, 194, 22);
 font-family: Helvetica, Arial, sans-serif;
 font-weight: bold;
 font-size: 34px;
 margin-left: 8px;
 margin-right: 8px;
 text-align: center;
}
.form-sub{
  margin-top: 20px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  text-align: center;
  font-size: 15px;
}
.form-input{
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 18px;
  gap: 4px;
  input{
    border-radius: 7px;
    height: 32px;
  }
}
.form-input-check{
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 18px;
  gap: 8px;
  cursor: pointer;
  input{
    border-radius: 7px;
    height: 32px;
    width: 18px;
  }
}
.form-informe{
  color: rgb(255, 255, 255);
  font-family: Source Sans Pro, sans-serif;
  font-size: 12px;
  text-align: center;
}
.form-button{
  color: rgb(244, 194, 22);
  font-size: 12px;
  text-align: center;
  height: 50px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  button{
    color: rgb(0, 60, 127);
    font-size: 18px;
    font-weight: 600;
    width: 95%;
    height: 95%;
    border-radius: 15px;
    background-color:  rgb(244, 194, 22);
  }
}
/* -------------------------- */
.box-azul-grid{
  background-color: rgb(3, 56, 120);
  width: 100%;
  height: 415px;
  display: flex;
  padding: 50px;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;
}
.grid-item{

  height: 132px;
  width: 480px;
}
.grid-a{
}
.grid-b{
}
.grid-c{
}
.grid-d{
}
/*****************************/
a{
  height: 59px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
 
  padding-top: 10px;
  padding-bottom: 10px;
  gap:12px
}
a:hover{
  border-radius: 15px;
  background-color: rgb(3, 56, 120);
  color: white;
}
a:active{
  border-radius: 15px;
  background-color: rgb(41, 75, 118);
  color: white;
}


/* -------------------------- */
.box-branco{
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 580px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.box-branco-titulo{
    color: rgb(0, 60, 127);
    font-family: "Arial Black", Gadget, sans-serif;
    font-size: 27px;
    margin-bottom: 17px;
}
.box-branco-contatos{
  color: rgb(0, 60, 127);
  font-size: 18px;
  gap: 35px;
  display: flex;
  flex-direction: column;
}
/* -------------------------- */
.box-fim{
  background-color: rgb(0, 60, 127);
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
/**/

.lead-obrigado{
  background-color: #05773c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  height: 100%;
}

.lead-erro{
  background-color: #772005;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  height: 100%;
  color: white;
  gap: 15px;
}

.lead-obrigado p{
  font-size: 25px;
  color: rgb(255, 255, 255);

}

/**************************************************************/
@media screen and (max-width: 760px){
  .box-form{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 5px;
  }
  .box-azul-grid{
    height: auto;
  }
  .box-form-a{
    width: auto;
  }

  .box-form-b{
    width: auto;
    padding: 5px;
  }
  


  .form-titulo{
    font-size: 26px;
  }

  .form{
    width: auto;

  }

  .box-azul-grid{
    padding: 5px;
    justify-content: center;
  }

  .grid-item{
    width: auto;
    align-items: center;
    justify-content: center;
    gap: 5px;
  
    img{
      max-height:  90px;
      width: 90px;
      margin-left: 15px;
      margin-right: -10px;
    }
  }

  .box-azul-grid{
    height: auto;
  }

.img-topo{
  img{
    height: 140px!important;
    
  }
}
  
  .box-branco-titulo{
    color: rgb(0, 60, 127);
    font-family: "Arial Black", Gadget, sans-serif;
    font-size: 17px;
    padding: 50px;
    text-align: center;
  }
  .box-branco-contatos{
    color: rgb(0, 60, 127);
    font-size: 22px;
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
}